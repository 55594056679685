.moreContainer {
    padding-top: 5%;
    padding-left: 12%;
    padding-right: 12%;
    background-color: #f1f1f1;
}
.moreMobileContainer {
  padding-top: 5%;
}

.moreMobileCol {
  padding-left: 0% !important;
  padding-right: 0% !important;
}
.applyContainer {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;  
    background-color: #f1f1f1; 
}

.applyMobileContainer {
  padding: 5%;
  background-color: #f1f1f1;
}

.contactContainer {
    padding-top: 5%;
    padding-left: 12%;
    padding-right: 12%;
    background-color: #f1f1f1;
}

.contactMobileContainer {
  padding: 5%;
}

.officeRow h4 {
  text-align: initial;
  margin-left: 18% !important;
  font-weight: 600;
  font-size: 20px;;
}
.contactMobileContainer h4{
  text-align: initial;
  font-size: 18px !important;
}

.contactMobileContainer h1 {
  line-height: 1.2em;
    font-size: 26px !important;
    color: #FFCE07;
    text-align: initial;
    font-weight: 700 !important;
    width: 80%;
}

.contactMobileContainer p {
  text-align: initial;
  line-height: 1.9em;
    font-size: 14px !important;
    font-weight: 300 !important;
    width: 70%;
}

.moreOne {
    width: 980px;
    height: 352px;
    object-fit: cover;
}

.moreMobileOne {
  width: 350px;
  height: 200px;
  object-fit: cover;
  width: 100% !important;
}

.formControlContact {
  width: 200px !important;
  background-color: #f1f1f1 !important;
}

.sydney {
    width: 358px;
    height: 212px;
    object-fit: cover;
    object-position: 50% 50%;
    margin: 0% !important;
}

.officeRow {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
    padding-bottom: 3%;
}

.officeMobileRow{
  /* padding-left: 8%;
  padding-right: 8%; */
  /* padding-top: 8%; */
  padding-bottom: 3%;
}

.officeMobileRow h4{
  text-align: initial;
  font-size: 23px !important;
  padding-left: 4%;
}

.applyContainer h4 {
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: initial;
    padding-left: 5%;
}

.applyMobileContainer h4 {
  font-size: 20px !important;
    font-weight: 500 !important;
    text-align: initial;
    /* padding-left: 5%; */
}


.contactContainer h4 {
  font-size: 22px !important;
    font-weight: 600 !important;
    text-align: initial;
    padding-left: 5%;
}

.centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px !important;
    background-color: white;
    padding: 10px 20px;
    width: 30%;
    text-align: center;
    font-weight: 700 !important;
  }

  .mobileCentered {
    position: relative;
    top: -30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px !important;
    background-color: white;
    padding: 10px 20px;
    width: 80%;
    text-align: center;
    font-weight: 700 !important;
  }

  .applyCol {
    background-color: #fff;
    margin-left: 1%;
    height: 550px;
    width: 100%;
  }

  .applyCol .cardText a{
    color: black !important;
  }

  .applyMobileCol {
    background-color: #fff !important;
    /* height: 350px !important; */
    padding: 2%;
    height: auto;
    width: 100% !important;
  }

  .colMobile12 {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

  .applyMobileCol .cardTitle {
    font-size: 18px !important;
    font-weight: 500 !important;
    text-align: initial;
    padding-left: 5% !important;
    padding-top: 10%;
  }

  .applyMobileCol .cardText {
    font-size: 13px !important;
    font-weight: 300 !important;
    text-align: initial;
    line-height: 2;
    width: 95%;
    padding-left: 5% !important;
    padding-top: 4%;
  }

  .applyMobileCol .cardText a{
    /* text-decoration: none !important; */
    color: black !important;
  }

  .applyContainer h1 {
    padding-left: 5%;
    margin-top: 1%;
    font-size: 45px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
    font-family: 'Poppins';
  }

  .applyMobileContainer h1 {
    /* padding-left: 5%; */
    margin-top: 1%;
    font-size: 26px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
  }
  

  .contactContainer h1 {
    padding-left: 5%;
    margin-top: 1%;
    font-size: 45px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
    font-family: 'Poppins';
  }

  .applyText {
    margin-bottom: 3%;
    width: 50%;
    margin-top: 1.5%;
    line-height: 1.4em;
  }

  .applyMobileText {
    margin-bottom: 3%;
    width: 100%;
    margin-top: 8%;
    line-height: 1.4em;
  }

  .cardTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: initial;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0%;
    line-height: normal;
  }

  .cardText {
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: initial;
    line-height: 2;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0%;
    /* width: 90%;
    padding-left: 15%;
    padding-top: 4%; */
  }

  .applyButton {
    background-color: white !important;
    margin-left: 0%;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: 1px solid #ffce05 !important;
    font-weight: 300 !important;
    color: black;
    margin-top: 2%;
  }

  .applyMobileButton {
    background-color: white !important;
    margin-left: -38%;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: 1px solid #ffce05 !important;
    font-weight: 300 !important;
    color: black;
    margin-top: 2%;
  }
  .applyButton:hover{
    background-color: white !important;
    margin-left: 0%;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: 1px solid black !important;
    font-weight: 300 !important;
    color: black;
    margin-top: 2%;
  }

 .contactImage {
    width: 786px;
    height: 587px;
    object-fit: cover;
    object-position: 50% 50%;
}

.contactContainer h4,.contactContainer h1,.contactContainer p {
    padding-left: 10%;
}
 
.contactContainer p{
    text-align: initial;
    font-weight: 300 !important;
    margin-top: 1%;
}

.formControls {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid black !important;
    width: 75%;
}

.formMobileControls {
  border-radius: 0px;
    border: none;
    border-bottom: 1px solid black !important;
    width: 100%;
}

.secondCol {
    margin-right: 20%;
}
.contactForm {
    margin-top: 8%;
}

.errorMessage {
  text-align: initial;
}

.errorTitle {
  color: red !important;
}

.label{
    padding-left: 0% !important;
    padding-top: 2%;
}

.applyText a{
  color: black !important;
}

.applyMobileText a{
  color: black !important;
}

.contactSecondRow {
    margin-top: 5%;
}

.form-control.formTextArea {
    width: 76%;
    border-radius: 0px;
    border: 1px solid black;
    height: auto;    
    background-color: #f1f1f1;
  }
  
.label span {
  color: red !important;
  padding-left: 3px;
}

.formMobileTextArea {
  width: 100%;
    border-radius: 0px;
    border: 1px solid black;
    height: auto !important;
    
}

.okButton {
  background-color: #ffce05 !important;
  padding: 10px 30px !important;
  font-size: 12px !important;
  border-radius: 0px !important;
  border: none !important;
  color: black !important;
  font-weight: 300 !important;
}

.contactMoreButton {
    background-color: #ffce05 !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 1px solid #ffce05 !important;
    color: black !important;
    font-weight: 300 !important;
    margin-top: 8%;
   
  }

  .contactMoreButton:hover {
    background-color: white !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    color: black !important;
    border: 1px solid black !important;
    font-weight: 300;
  }

  .successTitle {
    color: green !important;
  }


  @media only screen and (max-width: 1536px) { 
    .centered {
      text-align: center;
      font-size: 42px !important;
      width: 35%;
      top: 55%;
      line-height: normal;
      font-family: 'Montserrat';
    }

    .applyCol {
      background-color: #fff;
      margin-left: 0%;
      height: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  .contactImage {
    width: 610px;
    height: 587px;
    object-fit: cover;
    object-position: 50% 50%;
  }


.officeRow h4 {
  text-align: initial;
  margin-left: 12% !important;
}
   }
 