.citizenContainer {
    padding-top: 5%;
    padding-left: 20%;
    padding-right: 20%;
}

.citizenMobileContainer {
    padding-top: 50px;
}

.citizenMobileContainer h1{
    font-size: 28px !important;
    font-weight: 800 !important;
    text-align: initial;
    width: 70% !important;
    font-family: 'Montserrat';
    line-height: 1;
}

.cardimage{
    width: 257px;
    height: 164px;
    object-fit: cover;
    margin-right: 25%;
    margin-top: 5%;
}

.cardMobileimage{
    width: 180px;
    height: 115px;
    object-fit: cover;
    margin-top: 5%;
    margin-right: 52%;
}

.cardMobileimage2 {
    width: 180px;
    height: 115px;
    object-fit: cover;
    margin-top: 5%;
    margin-right: 20%;
}

.logoimage{
    width: 177px;
    height: 88px;
    object-fit: cover;
    margin-top: 35%;
    margin-right: 22%;
}

.logoMobileimage {
    width: 71px;
    height: 35px;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 26% !important;
}

.secondContainerCitizen {
    width: 100% !important;
    margin-top: 3%;
    padding-top: 3%;
    background-color: #f2f2f2;
    justify-content: center;
}

.emblum {
    width: 112px;
    height: 95px;
    object-fit: cover;
    object-position: 50% 50%;
}

.emblum2{
    width: 118px;
    height: 105px;
    object-fit: cover;
    object-position: 50% 50%;
}

.emblum3{
    width: 105px;
    height: 105px;
    object-fit: cover;
    object-position: 50% 50%;
}


.thirdCitizenRow {
    margin-top: 4%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #f1f1f1;
    padding-bottom: 4%;
    height: 460px;
}

.citizenshipMainContent {
    text-align: initial;
    font-weight: 300 !important;
    font-size: 15px;
    line-height: 1.6em;
    letter-spacing: 1px;
}

.citizenContainer h1{
    color: black !important;
    font-size: 55px !important;
    font-weight: 800 !important;
    font-family: 'Poppins';
    margin-bottom: 0;
    text-align: left;
    margin-left: 12%;
} 

.citizenContainer h2 {
    color: #FFCE07;
    font-size: 30px !important;
    font-weight: 500;
    text-align: center;
    margin-right: 30%;
    /* width: 60%; */
    /* padding-left: 14%; */
}
h1 span {
    color: #ffce05;
}

.secondCitizenRow {
    margin-top: 5%;
    margin-left: 0%;
}

.secondMobileCitizenRow {
    margin-top: 5%;
}

.secondCitizenRow p{
    width: 88%;
    margin-top: 12%;
    font-size: 13px !important;
    text-align: left;
}

.citizenSecondMobileRow {
    background-color: #f1f1f1;
    padding: 5%;
}

.citizenSecondMobileRow h1{
   font-size: 24px !important;
   font-weight: 500 !important;
   text-align: center !important;
   color: #E3B90F;
   width: 100% !important;
    font-family: 'Poppins';
}

.citizenSecondMobileRow h3 {
    font-size: 16px !important;
    text-align: center;
    font-weight: 300 !important;
    line-height: 1.4em !important;
    letter-spacing: normal;
}
.secondMobileCitizenRow p {
    text-align: initial;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 1.6em;
    letter-spacing: 1px;
    margin-top: 4%;
}

.secondMobileCitizenRow h2{
    font-size: 24px !important;
    text-align: initial;
    color: #ffce05;
    font-weight: 700 !important;
    font-family: 'Poppins';
}

.firstColPara {
    font-size: 12px !important;
    line-height: 1.6em !important;
}

.thirdColPara {
    font-size: 13px !important;
    line-height: 2.0em !important;
}

.secondColPara {
    font-size: 13px !important;
    line-height: 2.0em !important;
}

.mobileEmblum {
    width: 80px;
    height: 68px;
    object-fit: cover;
    object-position: 50% 50%;
}

.mobileEmblum2 {
    width: 80px;
    height: 72px;
    object-fit: cover;
    object-position: 50% 50%;
}

.mobileEmblum3 {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: 50% 50%;
}

.mobilesNumbers h1{
    font-size: 40px !important;
    font-weight: 800 !important;
}
.mobilesNumbers h3 {
    color: black;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-align: initial;
    margin-left: 22% !important;
    font-family: 'Poppins';
}

.mobilesNumbers h5{
    font-size: 14px !important;
    font-weight: 700 !important;
    text-align: initial;
    margin-left: 20% !important;
    font-family: 'Poppins';
}



.secondContainerCitizenDiv h1{
    text-align: center;
    color: #E3B90F !important;
    font-family: 'Poppins';
    font-weight: 900;
}

.secondContainerCitizenDiv h3{
    text-align: center;
    font-size: 22px !important;
    font-weight: 300 !important;
    /* width: 80%; */
    padding-left: 18%;
    padding-right: 18%;
    line-height: 1.4em;
    letter-spacing: 0.7px;
}

.secondContainerCitizen .thirdRow {
    margin-top: 6%;
}

.thirdRow {
    margin-left: 8%;
    margin-right: 8%;
    /* padding-bottom: 3%; */
}


.numbersRow {
    /* padding: 5%; */
    /* padding-bottom: 8%; */
    background-color: white !important;
    /* height: 420px; */
    padding: 8% 5%;
    display: flex;
    /* flex: 1; */
    align-items: flex-end;
    justify-content: space-between;
}

.numbersRow h1 {
    font-size: 110px !important; 
    text-align: center;
    color: #E3B90F !important;
    font-weight: 700 !important;
    font-family: 'Montserrat';
}

.numbersRow h3 {
    text-align: initial;
    /* margin-left: 22%; */
    font-weight: 700 !important;
    font-family: 'Poppins';
}

.numbersRow h5{
    text-align: initial;
    /* margin-left: 73%; */
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-bottom: 0% !important;
    font-family: 'Poppins';
}

@media only screen and (max-width: 1536px) { 
    .citizenContainer h2 {
        /* width: 70%; */
    /* padding-left: 18%; */
    font-family: 'Poppins';
    margin-left: 12%;
    }

    .citizenContainer {
        overflow-x: hidden !important;
    }

    .citizenshipMainContent {
        letter-spacing: 0.4px !important;
    }

    .numbersRow h5{
        /* margin-left: 71% !important; */
    }
    .numbersRow h3 {
        text-align: initial;
        /* margin-left: 22%; */
        font-weight: 700 !important;
        margin-bottom: 0% !important;
    }
    .citizenContainer h1{
        color: black !important;
        font-size: 54px !important;
        font-weight: 900 !important;
        text-align: initial;
        margin-left: 0;
    } 

    .familyText {
        /* margin-left: 15% !important; */
    }
 }
