.investFormContainer {
    padding-top: 1%;
    padding-left: 25%;
    padding-right: 25%;
    background-color: black;
    padding-bottom: 5%;
}

.investFormMobileContainer {
    padding-top: 1%;
    background-color: black;
    padding-bottom: 5%;
}
.investFormMobileContainer h2 {
    color: #ffce05;
    text-align: center;
    line-height: 1.2em;
    font-size: 28px !important;
    padding-top: 6%;
    font-weight:  600 !important;
}

.investFormMobileContainer p {
    color: rgb(255, 255, 255);
    font-size: 16px !important;
    font-weight: 300;
    letter-spacing: 0.2;
    text-align: center;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 5%;

}

.FormContainer {
    padding-top: 0.2%;
    padding-left: 1%;
    padding-right: 1%;
    background-color:#ecedf3;
    padding-bottom: 5%;
    padding-top: 1%;
}

.investFormContainer h2 {
    color: #ffce05;
    text-align: center;
    line-height: 1.2em;
    font-size: 45px !important;
    font-family: 'Poppins';
}

.investFormContainer p {
    color: rgb(255, 255, 255);
    font-size: 16px !important;
    font-weight: normal;
    letter-spacing: 0.2;
    text-align: center;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 5%;
}

.headingCol {
    /* padding: 1%; */
    /* width: 99% !important; */
    background-color: #2c3346;
}

.formRow {
    padding: 1%;
    margin-left: 0% !important;
    margin-right: 0% !important;
}

.headingCol p {
    color: white;
    font-size: 28px !important;
    font-weight: 600 !important;
    padding: 3%;
    text-align: initial;
    font-family: 'Poppins';
    margin-bottom: 0;
}

.bodyCol {
    background-color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
    padding: 4%;
    /* width: 99% !important; */
}

.form-label span{
    color: red;
    padding-left: 3px !important;
}

.form-label {
    display: flex;
    font-weight: 600;
}

.referalInput {
    width: 50%;
    height: 40px;
}

.bodyCol p{
    text-align: initial;
    color: black;
    font-size: 12px !important;
    width: 50%;
    padding: 0%;
    line-height: normal;
    letter-spacing: normal;
}

.paraTag {
    text-align: initial;
    color: black;
    font-size: 12px !important;
    width: 80% !important;
    padding: 0%;
    line-height: normal;
    letter-spacing: normal;
}

/* .invalid-feedback {
    display: flex;
    font-size: 11px;
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 5px;
    width: 20%;
} */

.MobilefooterRow .footerGrid {
    margin-left: 5% !important;
}

.warning {
    font-size: 16px !important;
}

.form-check-label {
    display: flex !important;
    text-align: initial !important;
}

.datePick {
    display: table !important;
    height: 38px !important;
    font-size: 16px;
    width: 100% !important;
    border-radius: 5px;
    /* border: 0.5px solid rgba(0,0,0,0.2); */
    /* padding-left: 10px; */
  }

  .datePick .react-date-picker__wrapper{
    height: 38px !important;
    font-size: 16px;
    width: 100% !important;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,0.2);
  }

  .react-datepicker-wrapper {
    display: flex !important;
    width: 50% !important;
  }

  .countrySelect {
    height: 40px;
    width: 50% !important;
  }

  .form-select {
    height: 40px;
  }

  .bodyCol h6 {
    text-align: justify;
    color: #2C3345;
    font-weight: 400 !important;
   /* padding-left: 10%;
   padding-right: 10%; */
   line-height: 1.6em;
  }

  .form-control {
    height: 40px;
  }

  .sigContainer {
    width: 80%;
    height: 150px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 5px;
  }
  
  .sigPad {
    width: 100%;
    height: 100%;
  }

  .buttonsBlock { 
    width: 80%;
    margin: auto;
    padding-right: 0;
  }

  .button1 {
    /* width: 0%; */
    margin-left: 0%;
    background-color: #ffce05;
    color: #2c3346;
    border: 1px solid #ffce05;
    border-radius: 2px;
    font-size: 12px;
  }

  .mobileButton1 {
    width: 15%;
    margin-left: 45%;
    background-color: #ffce05;
    color: #2c3346;
    border: 1px solid #ffce05;
    border-radius: 2px;
    font-size: 12px;
  }

  .button2 {
    margin-left: 5px;
    /* width: 7%; */
    background-color: #fff;
    color: #2c3346;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 12px;
  }

  .mobileButton2 {
    margin-left: 1%;
    width: 16%;
    background-color: #fff;
    color: #2c3346;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 12px;
  }
  
  .sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 100%;
    background-color: white;
  }

  .parentButtons {
    display: flex;
    margin-top: 1%;
    justify-content: flex-end;
  }

  .submitBtn {
    width: 100%;
    background-color: #ffce05;
    color: #000;
    border: none !important;
    border-radius: 2px;
  }

  .signDocumentRow {
    margin-top: 0%;
  }

  .signDocumentContainer .InputWidth{
    height: calc(100vh - 160px);
    width: 100vw;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .successTitle {
    color: green !important;
  }

  .okButton {
    background-color: #ffce05 !important;
    padding: 10px 30px !important;
    font-size: 12px !important;
    border-radius: 0px !important;
    border: none !important;
    color: black !important;
    font-weight: 300 !important;
  }

  .errorTitle {
    color: red !important;
  }

  .invalid-feedback {
    text-align: initial !important;
  }

  .form-label {
    text-align: initial;
  }

  .errorMessageStyle {
        color: #dc3545 !important;
        text-align: "initial";
        font-size: 15;
        margin-top: 5;
        width: 100% !important;
  }

  .uploadStyle {
    width: 100% !important;
    color: #000 !important;
    background-color: #dae6ff;
    padding: 10px !important;
    margin-top: 1%;
    border-radius: 5px;
    padding-top: 25px !important;

  }

  .was-validated .form-check-input:invalid~.form-check-label {
       color: #000 !important;
  }

  .was-validated .form-check-input:valid~.form-check-label {
    color: #000 !important;
  }

  .was-validated .form-check-input:invalid {
    border-color: #000 !important;
  }

  .deleteButton {
    font-size: 20px;
    margin-left: 0% !important;
  }

  .fileName {
    width: 100% !important;
  }
  .signatureImage {
    margin-left: 20px;
  }

  .signDocumentRow .investNote {
    font-weight: 600;
    width: 80%;
    margin: auto;
    padding-top: 20px;
  }

  .signDocumentRow .mobileinvestNote {
    font-weight: 800;
    width: 100%;
    margin: auto;
    padding-top: 10px;
  }

  @media  (max-width: 620px) {
    .sigContainer{
      width: 80%;
    }
    .buttonsBlock{
    width: 80%;
    margin: auto;
    padding: 0;
    }
    .parentButtons{
      justify-content: flex-end;
    }
    .mobileButton1{
      width: auto;
      margin-left:0;
    }
    .mobileButton2{
      width: auto;
    }
    .react-datepicker-wrapper{
      width: 80% !important;
    }
  }