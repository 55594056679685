.App {
  text-align: center;
  /* overflow-x:  hidden !important; */
}

a.link {
  text-decoration: none;
  /* color: inherit; */
}

.NavBarDesktop .mobileLogo{
  width: 85px !important;
}

.AppBrowser {
  text-align: center;
  overflow-x:  hidden !important;
}

.form-control:valid {
  /* background-color: #fff !important; */
  background-image: none !important;
}

.form-select:valid {
  background-color: #fff !important;
  background-image: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.errorTitle{
  color: red;
}

.swipeButton {
  position: fixed; 
   width: 100%;
   left: 82%;
   bottom: 40px;
   height: 50px;
   width: 55px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
   color: green;
   margin-bottom: 10px;
   border: 1px solid black !important;
   border-radius: 0px !important;
   background-color: #ffce05 !important;
}

.browserButton {
  position: fixed; 
   width: 100%;
   left: 95%;
   bottom: 40px;
   height: 50px;
   width: 55px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
   color: green;
   margin-top: 20% !important;
   /* margin-bottom: 10px; */
   border: 1px solid black !important;
   border-radius: 0px !important;
   background-color: #ffce05 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




