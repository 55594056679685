
.mainContainer {
    margin: 0px !important;
    padding: 0% 0% !important;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.mainMobileContainer {
  margin: 0px !important;
  padding: 0% 0% !important;
  overflow-x: hidden;

}

.firstContainer {
    padding: 0px !important;
    overflow-x: hidden !important;
}

.nav-link .active {
  background-color: rgb(227, 185, 15) !important;
    color: rgba(0,0,0,0.8) !important;
    font-weight: 300 !important;
    border-right: 1px solid rgba(0,0,0,0.15) !important;
}

.navbar-nav .dropdown-menu.show{
  padding: 0;
}

.brand {
  margin-left: 0% !important;
  margin-top: 2%;
}
#background-video {
    left: 0;
    right: 0;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  .overlay {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    top: 0%;
    height: 70%;
    position: absolute;
   width: 100%;
    margin-top: 0%;
  }

.mobile {
  height: calc(70vh);
  position: relative;
}


  .mobileLogo {
    width: 138px !important;
    
    object-fit: cover;
  }

 /* .navbar-brand {
  margin-left: 5% !important;
 } */
  .mobile .overlay {
    /* margin-top:29%; */
    width: 100%;
    height: calc(70vh);
  }

  /* .mobile #background-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
  } */

  .mobile video{
    /* height: 70%; */
    height: calc(70vh);
    width: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
    position: absolute;
  }

  .secondMobileTwoContainer video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 1;
    position: absolute;
    left: 0;
    z-index: -999;
  }

  .mobileInvest {
    position: relative;
  }

  .mobileInvest .overlayInvest{
    padding-bottom: 20px;
  }

  .navbar-toggler{
    border: none !important;
    font-size: x-large !important;
  }

  .overlay img.logo {
    width: 140px;
    margin-left: 10%;
    margin-top: 10%;
  }

  .overlay img {
    display: block;
  }

  .homeButtons {
    display: flex;
    margin-left: 5%;
    margin-top: 20px;
  }

  .secondContent .homeButtons{
    margin-left: 10%;
  }

  .investButtons {
    display: flex;
  }

  .contactBtn {
    background-color: rgba(0,0,0,0.1) !important;
    margin-left: 1%;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: 1px solid white !important;
    font-weight: 300 !important;
  }

  .contactBtn:hover{
    background-color: white !important;
    margin-left: 1%;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: 1px solid white !important;
    font-weight: 300;
    color: black !important;
  }

  .investBtn {
    background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 1px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 300 !important;
  }

  .investButton {
    background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 1px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 300 !important;
    margin-left: 5% !important;
  }

  .investBtn:hover {
    background-color: black !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    color: rgb(227, 185, 15) !important;
    border: 1px solid rgb(227, 185, 15) !important;
    font-weight: 300;
  }

  /* .homeHeadings {
    margin-top: 14%;
  } */

  .mobile .homeHeadings {
    /* margin-top: 35%; */
    position: relative;
    /* top: 50%; */
    transform: translateY(-50%);
  }

  .mobileNav {
    background-color: #f1f1f1 !important;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%; 
    padding-bottom: 2% !important;
    /* padding-left: 6%; */
    /* padding-top: 1%; */
  }
  .navGroup {
    padding-top: 3% !important;
  }

  .homeMobileNav {
    background-color: rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%; 
  }

  .offcanvas{
    margin-top: 2% !important;
  }

  .mobile .mainHeading {
    font-size: 24px;
    font-weight: 700;
    text-align: initial;
    margin-left: 5% !important;
    /* padding: 25px; */
    width: 77%;
  }

  .mobile .secondHeading {
    font-size: 18px;
    font-weight: 700;
    text-align: initial;
    margin-left: 5% !important;
  }

  .mobile .homeButtons {
    display: flex;
    margin-left: 5%;
    margin-top: 15px;
  }

  .mobile .investBtn {
    background-color: rgb(227, 185, 15) !important;
    padding: 15px 25px !important;
    font-size: 15px !important;
    border-radius: 0px !important;
    border: none !important;
    color: black !important;
  }

  .mobile .contactButton {
    background-color: rgba(0,0,0,0.1) !important;
    margin-left: 4%;
    padding: 15px 25px!important;
    font-size: 15px !important;
    border-radius: 0px !important;
    border: 1px solid white !important;
    color: #fff !important;
  }


  .mainHeading {
      font-size: 60px;
      font-weight: 700;
      text-align: initial;
     margin-left: 5%;
     color: white;
  }

  .secondHeading {
    font-size: 35px;
    line-height: 1.4em;
    text-align: initial;
    margin-left: 5%;
    color: white;
    font-weight:700
  }

  .navItems {
    display: flex;
    border: 2px solid rgb(227, 185, 15) !important;
  }

  .navItems a a {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .navItems a.dropdown-item{
    padding: 0;
  }

  .navItems a.dropdown-item a{
    padding: 7px 16px;
  }


  .navDropDownItem {
    text-align: center !important;
  }

  .navDropDownItem:hover {
    background-color: lightgray !important;
  }

  .dropDown1 {
    border-bottom: 0.4px solid rgba(0,0,0,0.15) !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding: 0 !important;
  }

  .desktopDropDownNav .dropdown-toggle::after {
    display: none !important;
  }

  #navItem{
    padding: 0;
    display: flex
  }
  #navItem a {
    padding: 15px 25px !important;
  }

  .yellowItem2#navItem a{
    padding: 15px 5px !important;
  }

  .yellowItem {
    background-color: rgb(227, 185, 15) !important;
    color: rgba(0,0,0,0.8) !important;
    font-weight: 300 !important;
    border-right: 1px solid rgba(0,0,0,0.15) !important;
  }

  .yellowItem2 {
    background-color: rgb(227, 185, 15) !important;
    color: rgba(0,0,0,0.8) !important;
    font-weight: 300 !important;
  }

  .greyItem {
    background-color: transparent !important; 
    font-weight: 300 !important;
    color: rgba(0,0,0,0.8) !important;
    border-right: 1px solid rgba(0,0,0,0.15) !important;
    border-left: 1px solid rgba(0,0,0,0.15) !important;
  }

  .dropdown-menu {
    margin-top: 5% !important;
    left: -50% !important;
    border-radius: 0px !important;
    border: 2px solid rgb(227, 185, 15) !important;
    background-color: white !important;
    padding: 0;
  }

  .mobileNavItemResponsive .dropdown-menu { 
    border: none !important;
    background-color: #FFF !important;

   }

   .mobileNavItemResponsive .dropdown-item {
    text-align: center !important;
    margin-top: 5px !important;
    font-size: 18px !important;
   }


  .stretch {
    height: 400px;
    background: white;
    min-height: 100%;
    min-width: 100%;
    margin-top: 300px;
    padding: 0px !important;
    display: flex;
  }

  .mobileStretch {
    /* height: 350px; */
    background: white;
    min-height: 100%;
    min-width: 100%;
    margin-top: 50px ;
    padding: 0px !important;
  }

  .mobileStretch p {
    margin-top: 20px;
    padding: 20px 15px;
    font-size: 20px !important;
    text-align: initial;
    font-weight: 500 !important;
  }

  .mobileSecondRowAbout {
    margin-top: 8% !important;
  }

  .mobileSecondRowAbout h2.h2{
    font-family: 'Montserrat';
    margin-top: 50px;
  }
  .mobileSecondRowAbout h2{
    /* margin-left: 5%; */
    padding-left: 15px;
    padding-right: 15px;
    text-align: initial;
    font-weight: 700 !important;
  }

  .mobileSecondRowAbout p {
    text-align: initial;
    /* margin-left: 5%; */
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1.6em;
    font-weight: 300 !important;
    padding-top: 5px;
}

  

  .stretchTwo {
    height: 100%;
    background: white;
    min-height: 100%;
    min-width: 100%;
    padding: 0px !important;
    display: flex;
  }

  .columnOne {
    padding: 8%;
    padding-left: 10% !important;
    padding-top: 5% !important;

  }

  .columnTwo {
    padding-top: 4.5%;
  }

  hr{
    border-top: 1px solid black !important;
    opacity: 1 !important;
    width: 46px;
  }

  .columnOne h3 {
      text-align: initial;
      font-size: 26px;
      font-weight: 700 !important;
      font-family: 'Poppins';
  }

  .columnTwo h4 {
    text-align: initial;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

  .columnOne p{
    padding-top: 8%;
    text-align: initial;
    line-height: 1.9em;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 300;
  }

  .columnTwo p{
    text-align: initial;
    padding-top: 2%;
    text-align: initial;
    line-height: 1.9em;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 300;
    width: 60%;
  }
   
  .stretch h2{
    padding: 5% 5% 0% 12%!important;
    text-align: initial;
    color: #E3B90F !important;
    font-size: 43px !important;
    font-weight: 700 !important;
   line-height: 1.2em !important;
  }

  .stretch p{
    text-align: left !important;
    font-size: 22px !important;
    letter-spacing: 1;
    padding-left: 12% !important;
    width: 50%;
    font-weight: 300 !important;
    padding-top: 10px;
  }
 
  .ParallaxContent {
    background: none repeat scroll 0 0 #2581e8;
    padding: 42px 0;
    color:#FFF;	
  } 
  .ParallaxContent h2{
    color:#FFF;	
  }

  .ParallaxVideo{ 
    padding-top: 12%;
    padding-left: 15%;
    } 

    .ParallaxVideoTwo {
      padding-top: 0%;
    }
    .ParallaxVideoTwo video{
      min-width: 100%;
      position: absolute;
      /* top:0%; */
      z-index: -999;
      height: 780px;
    }
    .ParallaxVideo video{ 
      min-width: 100%;
      position: fixed;
      top:0;
      z-index: -999;
      height: 100% !important;
    }
    .homecontent h1 {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'Poppins';
    }

    .secondContainer {
      padding: 0px !important;
      /* background-color: #f7f7f7; */
      /* overflow-x: hidden !important; */
    }

    .secondContainer1 {
      padding: 0px !important;
      background-color: #f7f7f7;
    }

    /* .secondMobileContainer {
      margin-top: 475px !important;
    } */

    .secondMobileTwoContainer {
      margin-top: 30px !important;
      padding: 0% !important;
    }
    
    .thirdContainer {
      padding: 0px !important;
      background-color: #f1f1f1;
      /* height: 400px !important; */
      /* overflow-x: hidden !important; */
    }

    .thirdContent {
      padding-top: 2%;
      justify-content: center !important;
    }

    .footerHr {
      width:85% !important
    }
    .maplogo {
      width: 265px;
      height: 245px;
    }

    .mapMobilelogo {
      width: 98px;
    height: 91px;
    object-fit: cover;
    margin-top: 0%;
    }

    .numberColumns {
      padding-top: 40px !important;
    }

    .secondContentTwo {
      /* margin-right: 34% !important; */
      margin-top: 2.5% !important;
      position: absolute;
    margin-left: 50%;
    }

    .secondMobileContentTwo {
      margin-top: 2.5% !important;
      /* position: absolute; */
   margin-left: 60%;
  
    }

    .secondContentOne {
      position: relative;
    z-index: 999;
    /* padding-left: -5% !important; */
    }


    .readMorebutton {
    background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 2px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 200;
    margin-left: 12%;
    margin-top: 6%;
    }

    .readMoreMobileButton {
      background-color: rgb(227, 185, 15) !important;
    padding: 15px 40px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 2px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 200;
    margin-left: 5%;
    margin-top: 6%;
    }

    .readMorebutton:hover {
      background-color: black !important;
      padding: 15px 60px !important;
      font-size: 16px !important;
      border-radius: 0px !important;
      color: rgb(227, 185, 15) !important;
      border: 2px solid rgb(227, 185, 15) !important;
      font-weight: 400;
    }

    .learnMorebutton {
      background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 2px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 200;
    margin-top: 8%;
    }

    .learnMobileMorebutton {
      background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 2px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 200;
    margin-top: 4%;
    margin-left: 5%;
    }


    .learnMorebutton:hover {
      background-color: black !important;
      padding: 15px 60px !important;
      font-size: 16px !important;
      border-radius: 0px !important;
      color: rgb(227, 185, 15) !important;
      border: 2px solid rgb(227, 185, 15) !important;
      font-weight: 400;
      margin-top: 8%;
    }

    .mobileHr {
      width: 15% !important;
    margin-left: 8% !important;
    margin-top: 10% !important;
    }

    .mobileInvestHr {
      width: 15% !important;
    margin-left: 5% !important;
    margin-top: 2% !important;
    border-top: 1px solid white !important;
    }

    .secondContent h3{
      font-size: 22px !important;
      text-align: initial;
      padding-left: 10% !important;
      color: white;
    }

    .secondContent h1{
      font-size: 45px !important;
      text-align: initial;
      font-weight: 700 !important;
      padding-left: 10% !important;
      color: #FFCE07;
      font-family: 'Poppins';
    }

    .secondContent p{
      font-size: 16px !important;
      text-align: initial;
      font-weight: 400 !important;
      padding-left: 10% !important;
      color: white;
      letter-spacing: 0.3px;
      width:40%;
    }

.whiteHr {
  margin-left: 10% !important;
  opacity: 1 !important;
  border-top:1px solid white !important;
}

.offcanvas {
  width: 100% !important;
}


    .secondContent {
      padding: 8%;
      padding-left: 0% !important;
      padding-top: 5% !important;
    }

    .numberRow {
      margin-left: 17% !important;
      margin-top: 5% !important;
    }

    .numberRow h1 {
      color: white;
      font-size: 60px !important;
      font-family: 'Poppins';
    }
    .numberRow p{
      margin-bottom: 0% !important;
      width: 100% !important;
    }

    .uptoNumberColumns {
      padding-top: 16px !important;
    }

    .numberMobileRow h1 {
      text-align: initial;
      font-size: 36px !important;
      font-weight: 700 !important;
      padding-left: 4%;
      color: #fff;
      font-family: 'Poppins';
    }

    .numberMobileRow p {
      text-align: initial;
      font-size: 14px !important;
      padding-left: 5%;
      color: #fff;
      margin-bottom: 0% !important;
    }

    .footerRow {
      display: flex !important;
      margin: 0% 26% !important;
      justify-content: center !important;
    }
    .footerFirstCol.col{
      text-align: left;
    }

    .MobilefooterRow img{
      margin-right: 55% !important;
    }

    .MobilefooterRow h5{
      font-size: 24px !important;
    }

    .footerGrid {

      display: grid !important;
    }

    .MobilefooterRow .footerGrid{
      padding-left: 20px;
      padding-right: 20px;
    }

    .footerGrid h5 {
      text-align: initial;
      margin-left: 0% !important;
      margin-top: 5%;
      font-size: 22px;
      font-weight: 600 !important;
    }

    .footerGrid h6 {
      text-align: initial;
      /* margin-left: 5% !important; */
      width: 100% !important;
      font-size: 16px;
      font-weight: 300 !important;
      padding-left: 0% !important;
    }

    .footerFirstCol {
      padding-top: 2%;
    }

    .footerSecondCol h5 {
      padding-top: 10%;
      font-size: 22px !important;
      font-weight: 600;
    }

    .footerMobileSecondCol h5{
text-align: initial;
margin-left: 5% !important;
margin-top: 4%;
      font-weight: 600;
    }

    .footerMobileSecondColContent {
      text-align: initial;
      margin-left: 5% !important;
      margin-top: 8%;
      
    }

    .footerMobileSecondColContent h6{
      font-weight: 300 !important;
    }

    .footerMobileSecondColContent a {
      text-decoration: none !important;
      color: black !important;
    }

    .footerSecondCol h6{
      text-align: initial;
      font-size: 16px;
      font-weight: 300 !important;
      line-height: 1.6em;
      margin-bottom: 0;
    }

    .footerSecondColContent{
      margin-top: 5%;
      margin-left: 38%;
    }

    .footerCopyRights {
      margin-top: 3%;
    }

    .footerCopyRights h5 {
      font-size: 14px !important;
      line-height: 1.4em !important;
      font-weight: 300 !important;
      margin: 0% !important;
      padding-bottom: 2% !important;
    }

    .footerMobileCopyRights h5{
      font-size: 10px !important;
      line-height: 1.4em !important;
      font-weight: 300 !important;
      padding-top: 6%;
      padding-bottom: 6%;
      margin: 0% !important;
    }

    .footerSecondColContent h6 a {
      text-decoration: none !important;
      color: black !important;
    }



    .mobileNavbarOffCanvas {
      padding: 10% !important;
    }

    .mobileNavItemResponsive {
      text-align: center !important;
    font-size: 22px !important;
    margin-bottom: 6% !important;
    font-weight: 600 !important;
    }

    .offCanvasBody {
      padding: 10% !important;
    }

    .mobileNavItemResponsive .active {
      color: #E3B90F !important;
    }

    .mobileFooter h5{
        text-align: initial !important;
        margin-left: 5% !important;
    }

    .mobileFooter h6{
      text-align: initial !important;
      margin-left: 5% !important;
  }



  .NavBarContainer {
    margin-left: 10% !important;
    margin-right: 5% !important;
  }

#offcanvasNavbar-expand-sm {
    margin-top: 0% !important;
    border-left: none;
  }


  @media only screen and (max-width: 1536px) {
  .secondContentTwo {
    margin-left: 55% !important;
  }

  .secondContentOne h2{
    padding: 5% 5% 0% 10%!important;
    text-align: initial;
    color: #E3B90F !important;
    font-size: 43px !important;
    font-weight: 700 !important;
   line-height: 1.2em !important;
   font-family: 'Poppins';
  }

  .secondMobileContentOne h2 {
    padding: 0% 5% 0% 5%!important;
    text-align: initial;
    color: #E3B90F !important;
    font-size: 28px !important;
    font-weight: 700 !important;
   line-height: 1.2em !important;
   font-family: 'Poppins';
  }

  .secondInvestMobileContent{
padding-left: 5% !important;
padding: 10px;
padding-top: 45px !important;
  }

  .secondInvestMobileContent h3 {
    color: white;
    text-align: initial;
    font-size: 20px !important;
  }

  .secondInvestMobileContent h1 {
    color: #E3B90F;
    text-align: initial;
    font-size: 26px !important;
    font-weight: 700 !important;
    font-family: 'Poppins';
  }

  .secondInvestMobileContent p {
    font-size: 16px !important;
    text-align: initial;
    letter-spacing: 1.2px;
    color: #fff;
  }


  .stretch p{
    text-align: left !important;
    font-size: 22px !important;
    letter-spacing: 1;
    padding-left: 10% !important;
    width: 50%;
    font-weight: 300 !important;
    padding-top: 10px;
  }

  .readMorebutton {
    background-color: rgb(227, 185, 15) !important;
    padding: 15px 60px !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: none !important;
    border: 2px solid rgb(227, 185, 15) !important;
    color: black !important;
    font-weight: 200;
    margin-left: 10%;
    margin-top: 6%;
    }


    .navbar-toggler:focus {
      box-shadow: none !important;
    }

.stretchTwo {
    height: 950px !important;
    background: white;
    min-height: 100%;
    min-width: 100%;
    padding: 0px !important;
    display: flex;
}
  }





