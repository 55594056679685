.aboutContainer {
    padding-top: 5%;
    padding-left: 7%;
    padding-right: 7%;
    background-color: #f1f1f1;
}

.aboutMobileContainer {
    padding-top: 5% !important;
    padding: 0%;
    padding-left: 3%;
    padding-right: 3%;
}

.aboutCorpContainer {
    padding-top: 2%;
    padding-left: 7%;
    padding-right: 7%;
    background-color: #f1f1f1
}

.aboutMobileCorpContainer {
    
    padding: 3% !important;
    background-color: #fff
}

.aboutMobileCorpContainer h1{
    font-family: 'Poppins';
}

.aboutMobileCorpContainer .AboutCorpMobileRow .ColOne p{
    line-height: normal;
}

.aboutMobileCorpContainer .AboutCorpMobileRow .ColOne {
    padding-left:20px ;
    padding-right:20px ;
}

.aboutCorpContainer h1 {
    font-size: 45px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
    font-family: 'Poppins';
}

.aboutMobileCorpContainer h1 {
    font-size: 28px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
}

.aboutMobileCorpContainer h4{
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: initial;
}

.aboutCorpContainer h4 {
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: initial;
}

.aboutContainer h4 {
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: initial;
}

.aboutContainer h1 {
    font-size: 45px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
    font-family: 'Poppins';
}


.aboutMobileContainer h4 {
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: initial;
    padding-top: 20px;
}

.aboutMobileContainer h1 {
    font-size: 25px !important;
    font-weight: 700 !important;
    text-align: initial;
    color: #FFCE07 !important;
    font-family: 'Poppins';
}
.aboutOne {
    width: 1572px;
    height: 669px;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 2;
}

.aboutMobileOne {
    width: 350px;
    height: 213px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100% !important;
}

.aboutTwo {
    width: 920px;
    height: 702px;
    object-fit: cover;
    object-position: 50% 50%;
}

.aboutMobileTwo {
    width: 350px;
    height: 213px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100% !important;
}

.aboutMobileCol {
    padding-right: 0% !important;
    padding-left: 0% !important;
}

.aboutMobileTwoCorp {
    width: 350px;
    height: 213px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100% !important;
}

.AboutImageCol {
    padding-left: 0% !important;
    padding-right: 0% !important;
}


.AboutRow .ColOne{
    /* padding-top: 5%; */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AboutRow h4 {
    padding-top: 0;
}

.ColOne p {
    padding-top: 4%;
    text-align: initial;
    width: 60%;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}

.ColMobileOne {
    padding: 20px;
    text-align: initial;
    width: 100%;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}




.AboutRow .RowColSecond {
    padding-top: 15%;
    padding-left: 8%;
}

.RowColSecond p{
    padding-top: 4%;
    text-align: initial;
    width: 70%;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}

.AboutCorpRow h1 {
    width: 60%;
}

.AboutCorpThreeRow h1 {
    width: 80%;
}

.aboutTwoCorp {
    width: 920px;
    height: 640px;
    object-fit: cover;
    object-position: 50% 50%;   
}

.aeroplaneVideo {
    width: 920px;
    height: 649px;
    object-fit: cover;
    object-position: 50% 50%;
}

.aeroplaneMobileVideo {
    width: 350px;
    height: 213px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100% !important;
}
.aboutCorpTwo {
    width: 661px;
    height: 649px;
    object-fit: cover;
    object-position: 50% 50%;
}

.AboutCorpTwoRow {
    background-color: #fff !important;
    margin-top: 2%;
    margin-bottom: 2%;
}

.AboutCorpTwoRow .ColCorpOne {
padding-left: 0%;
}

.RowColCorpSecond {
    padding-top: 1%;
    padding-left: 10%;
}
.RowColCorpSecond h1{
   width: 80%;
}

.RowColCorpSecond p {
    padding-top: 4%;
    text-align: initial;
    width: 60%;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}

.logos {
    float: left ;
    width: 80px;
}

.AboutMobileRow .logos{
    float: none;
}

@media only screen and (max-width: 1536px) {
.aboutOne{ 
    width: 1316px;
    height: 669px;
    object-fit: cover;
    object-position: 50% 50%;
    
 }

 .aboutTwo {
    width: 762px;
    height: 702px;
    object-fit: cover;
    object-position: 50% 50%;
 }

 .AboutCorpMobileRow .ColOne p {
    padding-top: 4%;
    text-align: initial;
    width: 100%;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
 }

 .ColOne p {
    padding-top: 4%;
    text-align: initial;
    width: 75%;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}

.RowColSecond p {
    padding-top: 4%;
    text-align: initial;
    width: 85%;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 2em;
    letter-spacing: 0.8px;
    color: black !important;
}

.AboutRow .RowColSecond {
    padding-top: 15%;
    padding-left: 5%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.aboutTwoCorp {
    width: 720px;
    height: 649px;
    object-fit: cover;
    object-position: 50% 50%;
}

.aboutCorpTwo {
    width: 547px;
    height: 649px;
    object-fit: cover;
    object-position: 50% 50%;
}

.RowColCorpSecond {
    padding-top: 1%;
    padding-left: 5%;
}

.AboutCorpRow h1 {
    width: 75%;
}

.AboutCorpThreeRow h1 {
    width: 95%;
}
.aeroplaneVideo {
    width: 720px;
    height: 649px;
    object-fit: cover;
    object-position: 50% 50%;
}
}


